@import "../../../node_modules/nav-frontend-core/less/_variabler";

.nedsatt-funksjonsevne {
    .limit {
        display: block;
    }

    .to-blokk {
        display: block;
    }

    .hjelpemidler, .vansker {
        margin-bottom: 3rem;

        &-bilde {
            text-align: center;
            margin-bottom: 1.5rem;

            img {
                height: 173px;
                width: 263px;
            }
        }
    }

}

@media (min-width: @screen-sm-min) {
    .nedsatt-funksjonsevne {
        .to-blokk {
            display: flex;
            justify-content: space-between;
        }

        .hjelpemidler, .vansker {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 48%;
            margin-bottom: 0;
        }
    }
}
