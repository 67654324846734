@import "../../../../node_modules/nav-frontend-core/less/_variabler";

.videreutdanning-bolk {
    margin: 2rem 0;
    width: 100%;

    .lenkepanel {
        background-color: @navLysGra;
    }

    ul {
        margin: 0;
    }
}

@media (min-width: @screen-sm-min) {
    .videreutdanning-bolk {
        margin: 4rem 0;
    }
}
