@import "../../../node_modules/nav-frontend-core/less/_variabler";
@import "../../../node_modules/nav-frontend-core/less/_mixins";

.din-situasjon-infoboks {
    background-color: @white;
    border-radius: 4px;
    padding: 1rem 1rem 0 1rem;

    &__tekst{
        margin-bottom: 2rem;

        *:first-child {
            margin-bottom: 1rem;
        }
    }

    &__illustrasjon {
        width: 70%;
    }
}

@media (min-width: @screen-md-min) {
    .din-situasjon-infoboks {
        display: flex;
        align-items: center;
        flex-direction: row;
        border: 1px solid @navGra20;
        height: 17rem;
        padding: 0 0.5rem 0 4rem;

        &__tekst{
            margin-bottom: 0;
        }

        &__illustrasjon {
            margin: 0 3rem 0 0;
            align-self: flex-end;
            width: auto;
            height: auto;
        }
        &__lenke {
            text-align: left;
            padding: 0;
        }
    }

    .din-situasjon-infoboks--utenfor-arbeidslivet-lenge {
        padding: 0 4rem 0 4rem;
    }
}
