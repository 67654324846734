@import "../../../node_modules/nav-frontend-core/less/_variabler";
@import "../../../node_modules/nav-frontend-core/less/_mixins";


ul {
    padding: 0 0 0 1em;
}

li {
    padding: 0 0 1em 0;
}

.registreringspanel-wrapper {
    width: 100%;
    padding: 4rem 0;
    .panel {
        border-radius: 4px;
    }
}
.registrering {
    width: 100%;
    padding: 0;
    text-align: left;

    &__ikon {
        margin-bottom: 2rem;
    }
    &__innholdstittel {
        margin-bottom: 1rem;
    }

    &__brodtekst.typo-normal {
        margin: 0 0 1rem;
        text-align: left;

        @media (min-width: @screen-sm-min) {
            width: 70%;
        }
    }
    
    .inspirasjon-veiviserinngang--bytte-jobb{
        padding: 2rem 1rem;
        background-color: @grayBackground;
    }

    .uinnlogget {
        margin: 2rem auto;
    }
}

.registrering--situasjonsvelger {
    padding: 0;
}

.situasjonsside__innhold {
    .registrering__lenkepanel {
        text-align: left;
    }
}

@media (min-width: @screen-sm-min){
    .registrering {
        padding: 0;
    }

    .situasjonsside__innhold {
        .registrering__brodtekst {
            width: 50%;
        }
    }
}
