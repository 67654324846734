@import "../../../node_modules/nav-frontend-core/less/_variabler";

.jobbsoking-mia--bytte-jobb {
    .mia-infoboks {
        background-color: #fff;
    }
}

@media (min-width: @screen-sm) {
    .jobbsoking-mia {
        display: flex;
        justify-content: space-between;
    }
}
