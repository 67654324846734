@import "../../../node_modules/nav-frontend-core/less/_variabler";

.andre-hjelpetjenester {
    .veilederpanelboks {
        .nav-veilederpanel {
            margin-bottom: 3rem;
            padding: 8.25rem 0 0 0;
            border: 0;

            .veilederpanel-tittel {
                text-align: center;
                margin: 0 2rem;
            }

            .veilederpanel-tekst {
                margin: 1rem 2rem;
            }

            &:last-child {
                margin: 0 auto 3rem;
            }

            img {
                height: 100%;
            }
        }
    }

    .akutt-vanskelig {
        .akutt-vanskelig-tittel {
            text-align: center;
            margin-bottom: 2rem;
        }

        .nav-veilederpanel--plakat {
            margin-top: 5rem;
            border-color: @navRod;

            img {
                height: 100%;
            }
        }
    }

    .slette-innhold {
        border-radius: @border-radius-large;
        background-color: @navLysGra;
        padding: 1.4rem;

        &--bilde {
            margin: 2rem 0 -1.4rem;
            text-align: center;
        }
    }
}

@media (min-width: @screen-md-min) {
    .andre-hjelpetjenester {
        .veilederpanelboks {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;

            .nav-veilederpanel {
                width: 30%;
            }
        }

        .nav-veilederpanel--plakat {
            .dobbel-kol {
                padding: 1rem 4rem 2rem;

                .kol {
                    float: left;
                    width: 41%;

                    &:first-child {
                        margin-right: 7rem;
                    }
                }
            }
        }

        .slette-innhold {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2rem 4rem;

            &--tekst {
                width: 50%;
            }

            &--bilde {
                width: 38%;
                align-self: flex-end;
                margin-bottom: -2rem;
            }
        }
    }
}
