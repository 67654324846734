@import "../../../../node_modules/nav-frontend-core/less/_variabler";

.jobblyst-facebook {
    padding: 2rem 0;
    width: 100%;

    .lenkepanel-med-bilde {
        display: flex;
        flex-direction: column;
        background-color: @grayBackground;
        border-radius: 4px;
        border: 1px solid @navGra20;
        padding: 1rem 2rem 3rem;
        text-decoration: none;
        margin-bottom: 0;

        &:hover {
            box-shadow: none;
            text-decoration: none;

            .lenkepanel__heading {
                text-decoration: none;

                .lenkepanel-med-bilde__overskrift {
                    text-decoration: underline;
                }

                .lenke {
                    border-bottom-color: @navBla;
                }
            }
        }

        &__illustrasjon {
            margin: 1rem auto 2rem;
            width: auto;
            height: auto;
            display: block;
        }

        &__overskrift {
            margin: 0.5rem 0;
        }

        &__tekst {
            margin: 2rem 0 3rem 0;
            max-width: 30rem;
        }
    }
}

@media (min-width: @screen-sm-min) {
    .jobblyst-facebook {
        padding: 4rem 0;

        .lenkepanel-med-bilde {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding: 1rem 1rem 1rem 2rem;
            align-items: center;
            margin-bottom: 1.5rem;

            .lenkepanel__heading {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            &__tekst {
                margin: 3rem 0 3rem 1rem;
                .ingress-avstand {
                    margin-bottom: 0;
                }
            }

            &__illustrasjon {
                margin: 0 2rem 0 0;
            }
        }
    }
}
