@import "../../../node_modules/nav-frontend-core/less/_variabler";
@import "../../../node_modules/nav-frontend-core/less/_mixins";

.okonomi {
    padding: 2rem 0;

    &.okonomi--bytte-jobb {
        .lenkepanel {
            background-color: @white;
        }

        .okonomiskstotte--lenkepanel {
            border: 1px solid @navGra20;
            &__tekst {
                padding: 1rem 0.4rem 0;
            }
        }

        .lenkepanel__heading {
            flex-direction: column;
        }
    }
}

@media (min-width: @screen-md-min) {
    .okonomi {
        width: 57rem;
        padding: 4rem 0;

        &.okonomi--bytte-jobb {
            .okonomiskstotte--lenkepanel {
                flex-direction: row;
                padding-left: 0;
                padding-bottom: 1rem;

                &__tekst {
                    margin-left: 2rem;
                }
            }

            .lenkepanel__heading {
                display: flex;
                flex-direction: row;
            }

            .lenkepanel__indikator {
                margin-top: 0;
            }
        }
    }
}
