@import "../../../node_modules/nav-frontend-core/less/_variabler";

input.datovelger {
    width: 170px;
    border-radius: 4px;
    padding-left: .7rem;
    padding-right: .7rem;
}

.nav-datovelger {
    display: inline-block;
    .bn_date {
        position: absolute;
        top: 23px;
        left: 130px;
        border: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: url(../../img/kalender.svg) no-repeat center;
        background-size: 46px 46px;
        width: 40px;
        height: 40px;
        &:hover, &:focus {
            background-image: url(../../img/kalender_hover.svg);
            cursor: pointer;
        }
        &:focus {
            outline: none;
            box-shadow: 0 0 0 3px @orangeFocus;
        }
        & .disabled {
            background-image: url(../../img/kalender_disabled.svg);
        }
    }

    .nav-dato {
        display: inline-block;
    }

    .month-wrap {
        position: relative;
    }

    .bn_prev-label, .bn_next-label {
        position: absolute;
        left: -3000px;
    }

    .bn_prev, .bn_next {
        position: absolute;
        top: 3px;
        height: 20px;
        width: 10px;
        cursor: pointer;
        z-index: 10;
        &:focus {
            outline: 0;
            box-shadow: 0 0 0 3px @orangeFocus;
        }
    }

    .bn_prev {
        left: 0;
        &:before {
            content: " ";
            border-bottom: 10px solid transparent;
            border-right: 9px solid @navBla;
            border-top: 10px solid transparent;
            position: absolute;
        }
        &[aria-disabled=true] {
            &:before {
                border-right: 9px solid @navLysGra;
                cursor: default;
            }
        }
    }

    .bn_next {
        right: 0;
        &:after {
            content: " ";
            border-bottom: 10px solid transparent;
            border-left: 9px solid @navBla;
            border-top: 10px solid transparent;
            position: absolute;
        }
        &[aria-disabled=true] {
            &:after {
                border-left: 9px solid @navLysGra;
                cursor: default;
            }
        }
    }

    .month {
        position: absolute;
        width: 100%;
        text-align: center;
    }

    .cal-wrap {
        position: absolute;
        left: 0;
        top: 56px;
        z-index: 100;
        background-color: @white;
        border: 1px solid @navGra20;
        padding: 20px;
        border-radius: 2px;
    }

    .cal {
        border-collapse: collapse;
        width: 100%;
        margin-top: 35px;
        font-weight: 600;
        text-align: center;
        border-top: 1px solid @navGra20;
        &:focus {
            outline: 0;
        }
        abbr[title] {
            border: none;
            text-decoration: none;
        }
        th {
            padding-top: 10px;
            &:first-child {
                width: 36px;
            }
        }
        th, td {
            width: 44px;
            height: 34px;
        }
        td {
            &[role=gridcell] {
                cursor: pointer;
                &:hover, &.focus {
                    background-color: @orangeFocus;
                }
            }
            &.today {
                background-color: @navLysGra;
                color: @navMorkGra;
                &.focus {
                    color: @navMorkGra;
                }
            }
            &.ukenr {
                color: @navGra40;
                font-weight: 400;
                font-style: italic;
                border-right: 1px solid @navGra20;
                text-align: left;
                width: 36px;
            }
            &:last-child {
                color: @navRod;
            }
            &.inactive {
                color: @navGra40;
                &:hover {
                    background-color: @white;
                    cursor: default;
                }
            }
        }
    }

    .offscreen {
        position: absolute;
        left: -200em;
        top: -100em;
    }

    [aria-hidden="true"] {
        overflow: hidden;
        padding: 0;
        visibility: hidden;
        height: 0;
        opacity: 0;
    }

    [aria-hidden="false"] {
        visibility: visible;
        height: auto;
        opacity: 1;
        -webkit-transition: opacity 0.5s ease-out;
        transition: opacity 0.5s ease-out;
    }

    .info-wrap {
        text-align: right;
    }

    .bn_info {
        display: inline;
        position: relative;
        padding: 0 7px;
        color: @orangeFocus;
        font-weight: 600;
        font-size: 1.5rem;
        font-family: serif;
        font-style: italic;
        cursor: pointer;
        &:hover {
            color: @navBla;
        }
        &:focus {
            outline: 0;
            box-shadow: 0 0 0 3px @orangeFocus;
        }
        &.opp {
            &:before {
                border-top: none;
                border-bottom: 9px solid @navBla;
            }
        }
    }

    .shortcut_info {
        height: 0;
        overflow: hidden;
        background: @white;
        table {
            margin-top: 8px;
        }
        th, td {
            vertical-align: top;
            padding: 2px 5px 2px 0;
        }
        th {
            text-align: left;
            font-weight: normal;
            width: 160px;
        }
    }
}
