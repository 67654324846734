@import "../../../../node_modules/nav-frontend-core/less/_variabler";

.mote-nav-bolk {
    background-color: @white;
    border: 1px solid @navGra20;
    border-radius: 4px;
    margin: 2rem 0;
    padding: 2rem 2rem 0 2rem;
    width: 100%;

    &__tekst-innhold {
        margin-bottom: 2rem;

        .innholdstittel {
            margin-bottom: 1rem;
        }
    }

    &__illustrasjon {
        text-align: center;
    }
}

@media (min-width: @screen-sm-min) {
    .mote-nav-bolk {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin: 4rem 0;
        padding: 2rem 4rem 0 2rem;

        &__tekst-innhold {
            margin-left: 2rem;
        }

        &__illustrasjon {
            padding: 2rem 1rem 0 5rem;
        }
    }
}
