@import "../../../node_modules/nav-frontend-core/less/_variabler";

.datovelger {
    padding: 0.45rem;
    background-color: @white;
    border-radius: 4px;
    border: 1px solid @navGra20;
    box-sizing: border-box;
}

.datovelger-stor-inline {
    label {
        display: block;
    }
}

.dagpenger {
    .ekspanderbartPanel {
        margin-bottom: 1rem;
    }

    .panel.relatert-innhold {
        padding: 1rem;
    }
}
