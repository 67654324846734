@import "../../../../node_modules/nav-frontend-core/less/_variabler";
@import "../../../../node_modules/nav-frontend-core/less/_mixins";

.soketidspunkt-innhold {
    .hjelpetekst-parent {
        padding-right: 25px;
    }

    .komprimert-padding {
        padding-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
    }

    .sluttdato-form {
        position: relative;
        .datovelger-stor-inline {
            display: inline-block;
            label {
                margin-bottom: 0.5rem;
            }
            .datovelger {
                margin-right: 3rem;
            }
            .cal-wrap {
                top: 65px;
            }
        }
    }

    .nav-datovelger .bn_date {
        left: 170px;
        top: 23px;
    }

    .alertstripe__tekst > p {
        margin: auto;
    }
}

@media (min-width: 28rem) {
    .soketidspunkt-innhold {
        .datovelger-stor-inline {
            margin-bottom: 0.5rem;
        }

        .knapp {
            margin-bottom: 0;
        }
    }
}

@media (min-width: @screen-md-min) {
    .soketidspunkt-innhold {
        padding: 2rem 6rem 3rem;
    }
}
