@import "../../../node_modules/nav-frontend-core/less/_variabler";
@import "../../../node_modules/nav-frontend-core/less/_mixins";

.jobbsoking {
    margin: 2rem 0;
    width: 100%;
}

.jobbsoking-mia {
    width: 100%;

    .infoboks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: @white;
        border: 1px solid @navGra20;
        border-radius: 4px;
        padding: 3rem;
        margin-bottom: 1rem;

        &:hover {
            box-shadow: none;
            text-decoration: none;
        }

        .lenkepanel__indikator {
            margin: auto;
        }

        &__bilde {
            text-align: center;
        }

        &__illustrasjon {
            margin: 2rem auto 0;
        }
    }
}

@media (min-width: @screen-sm-min) {
    .jobbsoking-mia {
        .infoboks {
            width: 49%;

            &__illustrasjon {
                width: 135px;
                height: 135px;
                margin-top: 3rem;
            }

            &__tekst {
                max-width: 20rem;
                min-height: 8rem;
            }

            &__lenke {
                text-align: left;
                padding: 0;
            }
        }
    }
}

@media (min-width: @screen-md-min) {
    .jobbsoking {
        margin: 4rem 0;
    }

    .jobbsoking-mia {
        margin: 0;
    }
}
