@import "../../../node_modules/nav-frontend-core/less/_variabler";

.din-situasjon {
    padding: 2rem 0;
}

@media (min-width: @screen-md-min) {
    .din-situasjon {
        padding: 4rem 0;
        width: 100%;
    }
}
