@import "../../../node_modules/nav-frontend-core/less/_variabler";

.jobb-eu-eos-bolk {
    background-color: @white;
    border: 1px solid @navGra20;
    border-radius: 4px;
    margin: 2rem 0;
    padding: 2rem 1rem 0 1rem;

    &__bilde {
        text-align: center;
    }

    &__tekst-innhold {
        margin-bottom: 3rem;

        .innholdstittel {
            margin-bottom: 1rem;
        }
    }
}

@media (min-width: @screen-sm-min) {
    .jobb-eu-eos-bolk {
        display: flex;
        margin: 4rem 0;

        &__tekst-innhold {
            margin-top: 2rem;
            margin-left: 3rem;
        }

        &__bilde {
            img {
                padding: 2rem 1rem 0 5rem;
            }
        }
    }
}
