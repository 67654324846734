@import "../../../node_modules/nav-frontend-core/less/_variabler";

.jobbsoking--bytte-jobb {
    .jobbsoking-infoboks {
        background-color: #fff;
    }
}

@media (min-width: @screen-md) {
    .jobbsoking-mia {
        display: flex;
        justify-content: space-between;

        .jobbsoking-infoboks {
            margin-bottom: 0;
        }
    }
}
