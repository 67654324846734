@import '../../../node_modules/nav-frontend-core/less/_variabler';

.stillingsok {
    padding: 0;
    width: 100%;
    text-align: left;
}

.lenkepanel-content {
    padding: 1rem 0;
}

@media (min-width: @screen-sm-min) {
    .lenkepanel-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem 0;
    }

    .stillingsok {
        margin: 0 auto;
        padding: 0;
        width: 100%;
    }

    .arbeidsplassen-text {
        padding-left: 3rem;
    }
}