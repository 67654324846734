@import "../../node_modules/nav-frontend-core/less/_variabler";

.limit {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
}

@media (min-width: @screen-md-min) {
    .limit {
        width: 880px;
    }
}