@import "../../../node_modules/nav-frontend-core/less/_variabler";

.situasjonsvelger {
    .limit {
        display: block;
    }

    .situasjonsvelger__tittel.typo-innholdstittel {
        padding: 1rem 0;
        text-align: center;
        margin: 0 auto;
    }
}

.veiledning-tittel {
    margin-bottom: 1rem;
}

@media (min-width: @screen-md-min) {
    .situasjonsvelger {
        .situasjonsvelger__tittel.typo-innholdstittel {
            padding: 3rem 0 2rem;
            max-width: 22rem;
        }

        .underside-innhold {
            width: auto;
            display: flex;
            justify-content: space-between;
        }

        .panelkolonne {
            width: 49%;
            .panel {
                min-height: 100%;
            }
        }
    }
}
