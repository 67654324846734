@import "../../../node_modules/nav-frontend-core/less/_variabler";

.okonomi-oversikt {
    .ekspanderbartPanel {
        margin-bottom: 1rem;
    }
}

@media (min-width: @screen-sm-min) {
    .okonomi-oversikt {
        .panel-innhold {
            padding: 1.5rem 10rem 1.5rem 2rem;
        }
    }
}
