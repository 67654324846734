@import "../../../../node_modules/nav-frontend-core/less/_variabler";

.problemer-bolk {
    margin: 2rem 0;
    width: 100%;
}

.problemer-bolk-panel {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: @white;
    border-radius: 4px;
    border: 1px solid @navGra20;

    &:first-child {
        margin-bottom: 2rem;
    }

    &__tekster {
        padding: 2rem 2rem 0;
    }

    &__tittel {
        margin-bottom: 1rem;
    }

    &__ingress {
        margin-bottom: 2rem;
    }

    &__illustrasjon {
        margin: 2rem 2rem 0;
        width: 80%;
    }
}

@media (min-width: @screen-sm-min) {
    .problemer-bolk {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 4rem 0;

        &.to-kol {
            align-items: stretch;
        }
    }

    .problemer-bolk-panel {
        &:first-child {
            margin-bottom: 0;
        }

        width: 49%;
        margin-bottom: 0;

        &__tekster {
            padding: 3rem 3rem 0;
        }
    }
}
