@import "../../../node_modules/nav-frontend-core/less/_variabler";

.side-ung-lite-erfaring {
    .arbeidstrening-bolk {
        width: 100%;

        .lenkepanel {
            background-color: @navLysGra;
            border: none;
        }
    }
}
