@import "../../node_modules/nav-frontend-core/less/_variabler";

.underside-innhold {
    width: 100%;
    margin: 1rem auto;
}

@media (min-width: @screen-md-min) {
    .underside-innhold {
        width: 880px;
        margin: 3rem auto;
    }
}
