@appPrefixUrl: "veiledearbeidssoker";
@img: "/img/midlertidig";

@import "../node_modules/nav-frontend-core/less/core";
@import '../node_modules/nav-frontend-knapper-style/src/index';
@import '../node_modules/nav-frontend-paneler-style/src/index';
@import '../node_modules/nav-frontend-lenkepanel-style/src/index';
@import '../node_modules/nav-frontend-lenker-style/src/lenker-style';

body {
    background-color: @white;
}

.maincontent {
    padding: 0;
}

.blokk-center {
    margin: 0 auto;
}

.limit {
    padding: 1.4rem 1rem;
}

.relatert-innhold-boks {
    margin-top: 3rem;

    .relatert-innhold {
        background-color: @navLysBlaLighten80;
    }

    .relatert-innhold-lenkeliste ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
            border-bottom: 1px solid @navLysBlaLighten40;
            padding: 1rem 2rem;
        }
    }
}

.sjekkboks-liste {
    padding-left: 43px;
    position: relative;

    div.avsnitt {
        position: relative;

        p {
            margin-top: 0;
        }

        &:before {
            content: "";
            position: absolute;
            left: -25px;
            top: 3px;
            width: 25px;
            height: 25px;
            background-size: 32px 32px;
            background: @white url(./img/sjekk-sirkel.svg) no-repeat;
        }
        &:last-child:before {
            padding-bottom: 6px;
            height: 100%;
            min-height: 32px;
        }
    }
}

.sok-om-liste {

	ol {
		list-style: none;
		counter-reset: list-counter;

		li {
			counter-increment: list-counter;
			margin-left: 0.5rem;
			margin-bottom: 2rem;
			padding-left: 1rem;
			position: relative;

			&:before {
				content: counter(list-counter);
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;
				font-weight: bold;
				top: -0.7rem;
				left: -3rem;
				background-color: @navLysGra;
				border-radius: 50%;
			}
		}
	}
}

@media (min-width: @screen-md-min) {
    .limit {
        padding: 0;
    }

    .to-kol {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .kol {
            width: 49%;
        }
    }

    .relatert-innhold-boks {
        .panel.relatert-innhold{
            padding: 1rem 0 1rem 2rem;
        }
    }

    .hovedinnhold {
        width: 59%;
    }

    .relatert-innhold-boks {
        width: ~'calc(39% - 1rem)';
        margin: 1rem 0 0 1rem;
    }

    .panel-innhold.to-kol {
        justify-content: normal;
        margin: 2rem auto 1rem;
        padding: 0 1.5rem;
    }
}
