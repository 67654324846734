@import "../../../../node_modules/nav-frontend-core/less/_variabler";

.aap-chat-panel {
    display: flex;
    align-items: center;
}

.aap-chat-panel-ikon {
    visibility: hidden;
    display: none;
}

.aap {
    .ekspanderbartPanel {
        margin-bottom: 1rem;
    }
}

@media (min-width: @screen-md-min) {
    .aap {
        .panel-innhold {
            padding: 1.5rem 10rem 1.5rem 2rem;
        }
    }
    .aap-chat-panel-ikon {
        visibility: visible;
        display: inherit;
    }
    .aap-chat-panel-innhold {
        padding: 1.5rem 10rem 1.5rem 2rem;
    }
}