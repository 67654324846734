@import "../../../../node_modules/nav-frontend-core/less/_variabler";

.dagpenger-innhold {

    .dagpenger-belop--innhold {
        padding: 1rem 2rem;
    }

    .tabell {
        border-collapse: collapse;
        background-color: transparent;
        width: 100%;
        max-width: 100%;
        margin-bottom: 1.5rem;

        caption {
            text-align: left;
            font-weight: bold;
            padding: 1rem 0;
        }
    }

    .tabell th {
        border: 2px solid @navGra40;
    }

    table.tabell, .tabell td {
        border: 1px solid @navGra40;
    }

    .softbreaked {
        white-space: pre-line;
    }

    .tabell th, .tabell td {
        padding: 8px;
        line-height: 1.5;
        vertical-align: top;
        text-align: center;
    }
}

@media (min-width: @screen-md-min) {
    .dagpenger-innhold {
        padding: 2rem 6rem 3rem;
    }
}
