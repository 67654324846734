@import "../../node_modules/nav-frontend-core/less/_variabler";
@import "../../node_modules/nav-frontend-core/less/_mixins";

.banner {
    border-bottom: 5px solid @navBlaLighten60;
    background-color: @navBlaLighten80;
    position: relative;
    padding: 1rem 2rem;

    .banner-tekst {
        text-align: center;

        .typo-ingress {
            display: none;
        }
    }

    .banner-bilde {
        display: none;
    }
}

@media (min-width: @screen-md-min) {
    .banner {
        padding: 3rem 2rem;

        .limit {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            &:before, &:after {
                content: none;
            }

            &.uten-ingress {
                min-height: 5rem;
            }
        }

        .banner-tekst {
            text-align: left;
            width: 550px;
            min-height: 80px;

            &.uten-bilde {
                text-align: center;
                margin: 0 1rem;
                width: 100%;
                padding: 0 3rem;
                min-height: auto;
            }

            .typo-ingress {
                display: block;
            }
        }

        .banner-bilde-boks {
            position: absolute;
            right: 0;
            bottom: -3rem;
        }

        .banner-bilde {
            display: inline;
        }
    }
}
