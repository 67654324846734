@import "../../../node_modules/nav-frontend-core/less/_variabler";
@import "../../../node_modules/nav-frontend-core/less/_mixins";

.dagpenger-annenstotte {
    .knapp {
        white-space: normal;
    }

    .underside-innhold .knapp--hoved {
        margin-top: 0.6rem;
    }
}
