@import "../../../node_modules/nav-frontend-core/less/_variabler";
@import "../../../node_modules/nav-frontend-core/less/_mixins";

.okonomiskstotte {
    background-color: @white;
    border-radius: 4px;
    padding: 1rem 1rem 0 1rem;

    @media (max-width: 414px) {
        &__illustrasjon {
            width: 15rem;
        }
    }

    &__tekst {
        margin-bottom: 2rem;

        .innholdstittel {
            margin-bottom: 1rem;
        }
    }
}

.okonomiskstotte--lenkepanel.lenkepanel {
    flex-direction: column;
    background-color: @grayBackground;
    border-radius: 4px;
    border: 0;
    text-decoration: none;

    &:hover {
        box-shadow: none;
        text-decoration: none;
    }

    &__illustrasjon {
        margin: 0 auto 20px;
        width: auto;
        height: auto;
        display: block;
    }

    .lenkepanel__heading {
        display: flex;
        align-items: center;

        .lenkepanel__overskrift {
            margin: 0.5rem 0;
        }
    }

    &__tekst {
        margin-top: 1rem;
        margin-left: 0;
        max-width: 31rem;
    }

    .lenkepanel__indikator {
        margin: 2rem 0 1rem;
    }
}

@media (min-width: @screen-sm-min) {
    .okonomi .okonomiskstotte--lenkepanel.lenkepanel {
        flex-direction: row;
        max-width: 100%;
        width: 100%;
        margin-bottom: 0;
    }

    .okonomiskstotte {
        display: flex;
        align-items: center;
        flex-direction: row;
        border: 1px solid #b7b1a9;
        height: 17rem;
        padding: 0 0.5rem 0 4rem;

        &__illustrasjon {
            margin-left: 1rem;
            align-self: flex-end;
            width: auto;
            height: auto;
        }

        &__lenke {
            text-align: left;
            padding: 0;
        }

        &__tekst {
            margin-bottom: 0;
        }
    }

    .okonomiskstotte--lenkepanel {
        display: flex;
        align-items: center;
        padding: 0 1rem 0 0;
        margin: 0 0 0 1px;

        .lenkepanel__heading {
            display: flex;
            align-items: center;
        }

        &__tekst {
            margin: 3rem 0 3rem 3rem;
            .ingress-avstand {
                margin-bottom: 0;
            }
        }

        &__illustrasjon {
            margin: 0;
            background-image: url("../../img/utklippstavlen.svg");
        }
    }

    .okonomiskstotte--utenfor-arbeidslivet-lenge {
        padding: 0 4rem;
    }
}

